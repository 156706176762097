import Environment from '@/config/Environment';
import {displayClientError, handleGenericClientError} from '@/utils/errors';
import type {DependencyList, EffectCallback} from 'react';
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';

export function useEffectOnce(effect: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}

export function usePrevious(value: any, initialValue: any) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useEffectDebugger(
  effectHook: EffectCallback,
  dependencies: DependencyList,
  dependencyNames = [],
) {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps: object = dependencies.reduce((accum: object, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies);
}

export const useIsomorphicLayoutEffect = Environment.IsWeb ? useLayoutEffect : useEffect;

export function useTimeout(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }
    const id = setTimeout(() => savedCallback.current(), delay);
    return () => clearTimeout(id);
  }, [delay]);
}

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;

export function useDOMNode(): [(HTMLElement) => void, HTMLElement | null] {
  const [node, setNode] = useState<HTMLElement | null>(null);

  const ref = useCallback((node: HTMLElement) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  return [ref, node];
}

type UseAsyncResult<T> = XOR<
  {
    loading: true;
  },
  {
    loading: false;
    value: T;
  },
  {
    loading: false;
    error: Error;
  }
>;

export function useAsync<T>(
  callback: () => Promise<T>,
  dependencies: DependencyList = [],
): UseAsyncResult<T> {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [value, setValue] = useState<T>();

  const callbackMemoized = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    setValue(undefined);
    try {
      setValue(await callback());
    } catch (e) {
      setError(e as Error);
      throw e;
    } finally {
      setLoading(false);
    }
    // eslint rule will catch this in consumers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  useEffect(() => {
    callbackMemoized();
  }, [callbackMemoized]);

  if (loading) {
    return {loading};
  } else {
    if (!error) {
      // @ts-expect-error not sure how to fix this
      return {loading: false, value};
    } else {
      // @ts-expect-error not sure how to fix this
      return {loading: false, error};
    }
  }
}

// taken from https://gist.github.com/morajabi/523d7a642d8c0a2f71fcfa0d8b3d2846
export const useBoundingClientRect = (ref) => {
  const [rect, setRect] = useState(getRect(ref ? ref.current : null));

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    // Update client rect
    setRect(getRect(ref.current));
  }, [ref]);

  useLayoutEffect(
    () => {
      const element = ref.current;
      if (!element) {
        return;
      }

      handleResize();

      if (typeof ResizeObserver === 'function') {
        let resizeObserver: ResizeObserver | null = new ResizeObserver(() => handleResize());
        resizeObserver.observe(element);

        return () => {
          if (!resizeObserver) {
            return;
          }

          resizeObserver.disconnect();
          resizeObserver = null;
        };
      } else {
        // Browser support, remove freely
        if (typeof window !== 'undefined') {
          window.addEventListener('resize', handleResize);

          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current],
  );

  return rect;
};

function getRect(element) {
  if (!element) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
    };
  }

  return element.getBoundingClientRect();
}

// experimental `use` hook from react pulled until this becomes stable for suspense
// @see https://github.com/facebook/react/pull/25084
const PROMISE_STATUS = Symbol('PROMISE_STATUS');
const PROMISE_VALUE = Symbol('PROMISE_VALUE');
const PROMISE_REASON = Symbol('PROMISE_REASON');

export function use<T>(promise: Promise<T>): T {
  const modified = promise as any as Promise<T> & {
    [PROMISE_STATUS]: 'pending' | 'fulfilled' | 'rejected';
    [PROMISE_VALUE]: T;
    [PROMISE_REASON]: unknown;
  };

  switch (modified[PROMISE_STATUS]) {
    case 'fulfilled':
      return modified[PROMISE_VALUE];
    case 'rejected':
      throw modified[PROMISE_REASON];
    case 'pending':
      throw modified;
    default: {
      modified[PROMISE_STATUS] = 'pending';

      throw modified.then(
        (value) => {
          modified[PROMISE_STATUS] = 'fulfilled';
          modified[PROMISE_VALUE] = value;
        },
        (reason) => {
          modified[PROMISE_STATUS] = 'rejected';
          modified[PROMISE_REASON] = reason;
        },
      );
    }
  }
}

export function useWhyDidYouRender(componentName: string, props: any, state?: any) {
  const previousProps = useRef(props);
  const previousState = useRef(state);

  useEffect(() => {
    const changedProps = {};
    const changedState = {};

    for (const key in props) {
      if (props[key] !== previousProps.current[key]) {
        changedProps[key] = {
          previous: previousProps.current[key],
          current: props[key],
        };
      }
    }

    if (state) {
      for (const key in state) {
        if (state[key] !== previousState.current[key]) {
          changedState[key] = {
            previous: previousState.current[key],
            current: state[key],
          };
        }
      }
    }

    console.log(`[why-did-you-render] ${componentName}`, {changedProps, changedState});

    previousProps.current = props;
    previousState.current = state;
  });
}

/**
 * Helper: get the closest scrollable ancestor of a node.
 * Returns the scroll parent, or window if none found.
 */
function getScrollParent(node: HTMLElement): HTMLElement | Window {
  if (!node) {
    return window;
  }

  const regex = /(auto|scroll)/;
  const getStyle = (element) => window.getComputedStyle(element, null);
  const overflow = (element) =>
    getStyle(element).overflow + getStyle(element).overflowY + getStyle(element).overflowX;

  let parent = node.parentElement;
  while (parent) {
    if (parent === document.body) {
      return window;
    }
    if (regex.test(overflow(parent))) {
      return parent;
    }
    parent = parent.parentElement;
  }
  return window;
}

/**
 * Custom hook to detect when the bottom of a container is visible.
 * It covers three cases:
 * 1. The user has scrolled to the bottom.
 * 2. The viewport or container grows such that the bottom is now visible.
 * 3. On initial load, when the table is short enough that no scrollbar is present.
 *
 * @param ref - A React ref attached to an element inside your table/container.
 * @param onLoadMore - Callback to fetch more items.
 * @param threshold - Optional threshold (in pixels) to trigger loading (default 50).
 */
export function useInfiniteScroll(
  ref: React.RefObject<HTMLElement>,
  onLoadMore: () => void,
  options: {threshold?: number} = {},
): void {
  const {threshold = 50} = options;
  useEffect(() => {
    if (!ref.current) return;

    const container = ref.current;
    const scrollParent = getScrollParent(container);

    const checkIfBottomVisible = (): void => {
      if (!ref.current) return;
      if (scrollParent === window) {
        const rect = container.getBoundingClientRect();
        if (rect.bottom <= window.innerHeight + threshold) {
          onLoadMore();
        }
      } else {
        const sp = scrollParent as HTMLElement;
        if (sp.scrollTop + sp.clientHeight >= sp.scrollHeight - threshold) {
          onLoadMore();
        }
      }
    };

    // check on first load
    // TODO i don't think this is actually necessary due to how ResizeObserver works
    // checkIfBottomVisible();

    const handleScroll = (_event: Event): void => {
      checkIfBottomVisible();
    };
    scrollParent.addEventListener('scroll', handleScroll);

    let resizeObserver: ResizeObserver | null = null;
    if (scrollParent === window) {
      window.addEventListener('resize', checkIfBottomVisible);
    } else {
      resizeObserver = new ResizeObserver((asdf) => {
        checkIfBottomVisible();
      });
      resizeObserver.observe(scrollParent as HTMLElement);
    }

    return () => {
      scrollParent.removeEventListener('scroll', handleScroll);
      if (scrollParent === window) {
        window.removeEventListener('resize', checkIfBottomVisible);
      } else if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [ref, onLoadMore, threshold]);
}
